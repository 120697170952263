<script >

// import Header from "@/components/Header/index.vue";
import Footer from "@/components/Footer/index.vue";
import Header from "@/components/Header/index.vue";
export default {

  components: {
    Header,
    Footer,
    // Header,
    // Swiperlist,
  },
}
</script>

<template>
  <div>
    <Header />
    <main>
      <portal-target name="float-modal" class="float-portal-target"></portal-target>
      <router-view></router-view>  <!-- 动态内容根据子路由渲染 -->
    </main>
    <Footer />
  </div>
</template>

<style scoped>

</style>